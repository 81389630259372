import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";

export const loadBookingTransactions = (facilityId, arenaId, filters) => {
  const url = getURLWithQueryParams(API_URLS.BOOKING_REPORT, undefined, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};

export const loadCouponTransactions = (id, arenaId, couponId) => {
  const url = getURLWithQueryParams(API_URLS.COUPON_TRANSACTION, undefined, {
    id,
    arenaId,
    couponId
  });
  return tokenAxiosInstance.get(url, {
  
  });
};

export const loadOtherBookingTransactions = (facilityId, filters) => {
  const url = getURLWithQueryParams(API_URLS.OTHER_BOOKING_REPORT, undefined, {
    facilityId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};

export const loadAmountDueTransactions = (facilityId, arenaId, filters) => {
  const url = getURLWithQueryParams(API_URLS.AMOUNT_DUE_REPORT, undefined, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};

export const loadAccountsTransactions = (facilityId, arenaId, filters) => {
  const url = getURLWithQueryParams(API_URLS.ACCOUNTING_REPORT, undefined, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};

export const loadEnquiryTransactions = (facilityId, filters) => {
  const url = getURLWithQueryParams(API_URLS.ENQUIRY_REPORT, undefined, {
    facilityId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};
export const loadBDTransactions = (facilityId, filters) => {
  const url = getURLWithQueryParams(API_URLS.BD_REPORT, undefined, {
    facilityId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};

export const loadTrialReport = (facilityId, filters) => {
  const url = getURLWithQueryParams(API_URLS.TRIALS_REPORT, undefined, {
    facilityId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};



export const loadReleaseReport = (facilityId, filters) => {
  const url = getURLWithQueryParams(API_URLS.RELEASE_REPORT, undefined, {
    facilityId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};
export const loadBlockingReport = (facilityId, filters) => {
  const url = getURLWithQueryParams(API_URLS.BLOCKING_REPORT, undefined, {
    facilityId,
  });
  return tokenAxiosInstance.get(url, {
    params: {
      ...filters,
    },
  });
};



export const deleteTransaction = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.DELETE_TRANSACTION);
    return tokenAxiosInstance.delete(url, {data : postBody});
}

export const getEmployeesAttendance = (facilityId, arenaId, month) => {
  const url = getURLWithQueryParams(
    API_URLS.EMPLOYEES_ATTENDANCE,
    { month  },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const getLastReceipt = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.GET_RECEIPT_NUMBER, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.get(url);
};

export const editReceiptNumber = ( postBody) => {
  const url = getURLWithQueryParams(API_URLS.EDIT_RECEIPT_NUMBER);
  return tokenAxiosInstance.post(url, postBody);
};

export const changePaymentMode = ( postBody) => {
  let  { id,
  arenaId,
  bookingId} =postBody
  const url = getURLWithQueryParams(API_URLS.CHANGE_PAYMENT_MODE, null, {
     id,
    arenaId,
    bookingId
  });
  console.log("url",url)
  return tokenAxiosInstance.post(url, postBody);
};

 
export const getMonthlyDataBeforeCurrentMonth = (facilityId, arenaId,sportId,startDate) => {
  const url = getURLWithQueryParams(API_URLS.GET_MONTHLY_DATA_BEFORE_CURRENT_MONTH, null, {
    facilityId,
    arenaId,
    sportId,
    startDate
  });
  // console.log("getMonthlyDataBeforeCurrentMonth url", url)
  return tokenAxiosInstance.get(url);
};
