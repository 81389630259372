import React, { useContext, useState } from 'react';
// import { colors } from "@material-ui/core";
import {
    Button,
    Form,
    // Divider,
} from "semantic-ui-react";

import ModalPortal from '../generic/ModalPortal';
import { AppContext } from '../../AppContext';
import { changePaymentMode } from "../../apiclients/ReportsApiClient";
import Payment from "../dashboard/Payment";

const ChangePaymentMode = (props) => {
    const { showModal, setReload, setMessage,isLoading, amount, transactionId } = props;
     const context = useContext(AppContext);
    const {user } = context;
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const [paymentArray, setPaymentArray] = useState([]);
    const [totalPayment, setTotalPayment] = useState();


    const isSubmitAllowed =  totalPayment != null && totalPayment === amount;
    

    const updatePaymentArray = (updatedArray) => {
        const amountPaid = updatedArray.reduce((a, b) => a + Number(b.amount), 0);
        setTotalPayment(amountPaid);
        setPaymentArray(updatedArray);
         
    };

    const modalContent = (
        <div>
            <Form>  
                <>
                    <div style={{ paddingBottom: "10px" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Amount : Rs.
                        </span>
                        <span> {amount}/-</span>
                    </div>
                    <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                        Payment Breakup
                    </div>
                    <Payment
                        paymentArray={paymentArray}
                        setPaymentArray={updatePaymentArray}
                    />
                    <div style={{ paddingTop: "10px" }}>
                        <span style={{ fontWeight: "bold" }}>Total Payment: Rs. </span>
                        <span>{totalPayment}/-</span>
                    </div>
                </>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button primary disabled={!isSubmitAllowed} onClick={changePaymentModes}>  Submit</Button>
            </React.Fragment>
        );
    };

    const changePaymentModes = () => {
        showModal(false);
        isLoading(true);
        const postBody = {
            id:facilityId, arenaId, bookingId:transactionId, paymentInfo: paymentArray, role : user.role,
            user : {
                userName: context.user.userName,
                userId: context.user.userId,
            },
        };
// console.log("postBody",postBody)
        changePaymentMode(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Payment Updated",
                    message: "Payment has been updated successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            isLoading(false);
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }
    return (
        <>
            <ModalPortal
                title='Change Payment Mode'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}
export default ChangePaymentMode;