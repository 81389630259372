import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Button,
    Form,
} from "semantic-ui-react";
// import { colors } from "@material-ui/core";
import { AppContext } from '../../AppContext';
import ModalPortal from '../generic/ModalPortal';
import DatesSelection from '../common/DatesSelection';
import { addBanner, updateBanner } from '../../apiclients/WalletApiClient';
import moment from 'moment';

const AddBanner = (props) => {
    const { showModal, setReload, setMessage, isLoading, data, edit,setShowMessage,showMessage } = props;
    const context = useContext(AppContext);
    const [formData, setFormData] = useState({
        imageURL: "",
        redirectionURL: "",
        location: {
            lat: "",
            lon: ""
        },
        ranking: "",
        remarks: ""
    });
    const fileInputRef = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedFile, setSeclectedFile] = useState(null)
    // const [selectedBaseFile, setSeclectedBaseFile] = useState(null)

    const isSubmitAllowed = formData.remarks && formData.ranking && formData.location.lat && formData.location.lon && startDate && endDate;

    //    console.log("data,edit",data,edit)

    useEffect(() => {
        if (edit && data) {
            setFormData({
                imageURL: data.imageURL,
                redirectionURL: data.redirectionURL,
                location: data.location,
                ranking: data.ranking,
                remarks: data.remarks
            })
            setStartDate(firestoreTimestampToDate(data.startDate))
            setEndDate(firestoreTimestampToDate(data.endDate))

        }
    }, [data]);

    function firestoreTimestampToDate(timestamp) {
        const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
        return moment(milliseconds).format('YYYY-MM-DD HH:mm:ss'); // Format as desired
    }

    const changeDate = (isStart) => value => {
        if (isStart) {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    }

    const handleChange = (name, value) => {
        setFormData((prevState) => {
            if (name.includes(".")) {
                const [outerKey, innerKey] = name.split(".");
                return {
                    ...prevState,
                    [outerKey]: {
                        ...prevState[outerKey],
                        [innerKey]: value,
                    },
                };
            }
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    //    const handleLocationChange = (name ,value)=>{
    //     setFormData((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //       }));
    //    }
    const handleButtonClick = () => {
        fileInputRef.current.click(); // Programmatically trigger the file input
    };

    const uploadImage = (file) => {

        const fileSizeInBytes = file.size; // Get size in bytes
        const fileSizeInKB = fileSizeInBytes / 1024; // Convert to KB
        const fileSizeInMB = fileSizeInKB / 1024; // Convert to MB
        if (fileSizeInMB > 5) {
            // alert("File size must be less than 5MB");
            setShowMessage(true)
            setMessage({
                header: "Error!",
                message:  "Image size must be less than 5MB",
                color: "red",
            });
            return;
        }
        setShowMessage(false)
        setSeclectedFile(file)

    }
    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>

                    <Form.Field>
                        <label>Ranking</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            name='ranking'
                            value={formData.ranking}
                            placeholder='enter ranking'
                            onChange={(e) => handleChange("ranking", e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Redirection Url</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            name='redirectionURL'
                            value={formData.redirectionURL}
                            placeholder='enter redirection url'
                            onChange={(e) => handleChange("redirectionURL", e.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Lat</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            name="location.lat"
                            value={formData.location.lat}
                            placeholder='enter lat'
                            onChange={(e) => handleChange("location.lat", e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Lon</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={formData.location.lon}
                            name="location.lon"
                            placeholder='enter lon'
                            onChange={(e) => handleChange("location.lon", e.target.value)}
                        />
                    </Form.Field>
                </Form.Group>


                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            name='remarks'
                            value={formData.remarks}
                            placeholder='Enter remarks'
                            onChange={(e) => handleChange("remarks", e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <DatesSelection
                            startDate={startDate}
                            endDate={endDate}
                            changeDate={changeDate}
                            disablePast={true}
                        />
                    </Form.Field>


                </Form.Group>
                <Form.Group widths='equal'>


                    <Form.Field  >
                        <label>Upload Image</label>
                        <input
                            ref={fileInputRef}
                            style={{ display: 'none' }} // Hide the input box
                            type="file"
                            accept="image/*"
                            name="images"
                            onChange={(e) => uploadImage(e.target.files[0])} // Handle file selection
                        />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <Button onClick={handleButtonClick}>
                                    Choose File
                                </Button >
                                {showMessage&&(<p style={{color:"red"}}>Image size must be less than 5MB</p>)} 
                            </div>

                            {selectedFile ? (
                                <img src={URL.createObjectURL(selectedFile)} alt="Preview" style={{ width: '35vw', height: '14vw' }} />
                            ) : formData.imageURL ? (
                                <img src={formData.imageURL} alt="Preview" style={{ width: '35vw', height: '14vw' }} />
                            ) : null}
                        </div>

                    </Form.Field>

                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button
                    disabled={!isSubmitAllowed}
                    primary onClick={edit ? updateBannerFunction : addBannerFunction}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addBannerFunction = () => {
        isLoading(true);
        showModal(false);
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,

        };
        // const postBody= {
        //     redirectionURL:formData.redirectionURL,
        //     location:formData.location,
        //     ranking:formData.ranking,
        //     remarks:formData.remarks,
        //     file:selectedBaseFile,
        //     createdBy:createdBy,
        //     startDate:startDate,
        //     endDate,endDate
        // }
        const postBody = new FormData();
        postBody.append('redirectionURL', formData.redirectionURL); // Append text data
        postBody.append('location', JSON.stringify(formData.location));
        postBody.append('ranking', formData.ranking);
        postBody.append('remarks', formData.remarks);
        postBody.append('file', selectedFile); // Append the file
        postBody.append('createdBy', JSON.stringify(createdBy)); // Append text data
        postBody.append('startDate', startDate);
        postBody.append('endDate', endDate);

        addBanner(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                isLoading(false);
                setMessage({
                    header: "Banner Added",
                    message: "Banner has been added successfully.",
                    color: "green",
                });
            } else {
                isLoading(false);
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            isLoading(false);
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }

    const updateBannerFunction = () => {
        isLoading(true);
        showModal(false);
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        // const postBody= {
        //     id:data.id,
        //     redirectionURL:formData.redirectionURL,
        //     location:formData.location,
        //     ranking:formData.ranking,
        //     remarks:formData.remarks,
        //     file:selectedBaseFile,
        //     updatedBy:createdBy,
        //     startDate:startDate,
        //     endDate,endDate
        // }
        const postBody = new FormData();
        postBody.append('redirectionURL', formData.redirectionURL); // Append text data
        postBody.append('location', JSON.stringify(formData.location));
        postBody.append('ranking', formData.ranking);
        postBody.append('remarks', formData.remarks);
        postBody.append('file', selectedFile); // Append the file
        postBody.append('updatedBy', JSON.stringify(createdBy)); // Append text data
        postBody.append('startDate', startDate);
        postBody.append('endDate', endDate);
        postBody.append('id', data.id);
        updateBanner(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                isLoading(false);
                setMessage({
                    header: "Banner updated",
                    message: "Banner has been updated successfully.",
                    color: "green",
                });
            } else {
                isLoading(false);
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            isLoading(false);
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }

    return (
        <>
            <ModalPortal
                title={edit ? 'Edit Banner' : 'Create Banner'}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddBanner;